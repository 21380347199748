<template>
    <v-btn dark @click="onClick" :color="btnColor">{{ buttonText }}</v-btn>
    <!-- <v-btn dark v-on="$listeners" :color="btnColor">{{ buttonText }}</v-btn> -->
</template>
<script>
export default {
    name: 'ButtonComponent',
    props: {
        buttonText: {
            type: String,
            default: () => "Button",
        },

        btnColor: {
            type: String,
            default: () => "blue"
        },
        
        onClick: {
            type: Function,
            required: true
        },

    }

}
</script>
<template>
  <div class="home">
    <homeSlider />
    <rightStickIcon />

    <v-container fluid :class="{ 'pa-0': $vuetify.breakpoint.mdAndUp }">
      <v-container style="padding: 0 12px" class="searchBox">
        <v-card class="mx-auto mt-n12 rounded-xl">
          <v-row>
            <v-col cols="12" sm="3">
              <v-list-item two-line>
                <v-list-item-content class="find-flight">
                  <v-list-item-title
                    class="text-h6 text-xl-h3 text-lg-h4 text-md-h5 mt-8 ml-5"
                  >
                    Find your Flight
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-divider class="mt-5 mb-5" vertical></v-divider>
            <v-col cols="12" sm="3" class="InputSection">
              <v-list-item>
                <v-list-item-avatar rounded="0" size="18">
                  <v-img :src="require('../assets/images/search.svg')" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="">
                    <div class="mt-2">Search</div>
                    <v-text-field
                      class="mt-0 pt-3"
                      label="by City / Flight no."
                      v-model="searchFlight"
                    ></v-text-field>
                  </div>
                  <!-- <v-list-item-subtitle>by City / Flight no.</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-divider class="mt-5 mb-5" vertical></v-divider>
            <v-col cols="12" sm="3" class="InputSection">
              <v-list-item>
                <v-list-item-avatar rounded="0" size="20">
                  <v-img
                    :src="require('../assets/images/arrivaldepature.svg')"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <div>
                    <div class="mt-2">Arrivals</div>
                    <v-select
                      class="mt-0 pt-3"
                      label="or Departures"
                      hide-details
                      two-line
                      :items="['Arrivals', 'Departures']"
                      v-model="selectFlightMode"
                    >
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-title
                            :id="attrs['aria-labelledby']"
                            v-text="item"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </div>
                  <!-- <v-list-item-subtitle>or Departures</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              :class="{ buttonDesktop: $vuetify.breakpoint.mdAndUp }"
            >
              <v-list-item two-line>
                <v-list-item-content>
                  <v-btn
                    rounded
                    @click="findFlight"
                    color=""
                    dark
                    large
                    class="gradient-button common-btn mt-6 px-10"
                  >
                    Search
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card>

        <v-row>
          <v-col>
            <div
              class="searchTag ml-5 my-7"
              :class="{ searchTagSm: $vuetify.breakpoint.smAndDown }"
            >
              <p>Trending Searches</p>
              <span class="mt-2">BOM - MAA </span>
              <span class="mt-2">SIN - MAA </span>
              <span class="mt-2">DEl - MAA </span>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-card flat class="mt-10 mb-10" v-scrollanimation>
        <v-card-title
          class="justify-center text-uppercase page-title"
          :class="{ 'page-titleSm': $vuetify.breakpoint.smAndDown }"
        >
          Airport Guide
        </v-card-title>
        <v-card-subtitle
          class="text-center page-sub-title"
          :class="{ 'page-subtitleSm': $vuetify.breakpoint.smAndDown }"
        >
          <h1>How can we help?</h1>
        </v-card-subtitle>
        <v-tabs
          background-color=" accent-4"
          :centered="$vuetify.breakpoint.smAndDown ? false : true"
          :center-active="$vuetify.breakpoint.smAndDown ? true : false"
          class="mytabs mt-5"
          :class="{ responsiveTab: $vuetify.breakpoint.smAndDown }"
        >
          <v-tab href="#tab2" class="border-radiusbefore">
            Before You Fly
          </v-tab>
          <v-tab-item value="tab2">
            <v-row>
              <v-col cols="12" md="4">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`${hover ? 'imgover' : 'imgleave'}`"
                  >
                    <v-img
                      :height="400"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'rounded-xl'
                          : 'rounded-r-xl'
                      "
                      src="../assets/images/beforeflyTab.jpg"
                    ></v-img>
                  </div>
                </v-hover>
              </v-col>
              <v-col cols="12" md="7">
                <v-card class="mx-auto" width="90%" :elevation="0">
                  <v-card-text>
                    <p class="text-h4 text--primary">Before You Fly</p>
                    <p class="text--primary">
                      Are you preparing to fly from Chennai Airport? we are here
                      for a hassle-free travel experience and save your valuable
                      time and energy!
                    </p>
                  </v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <v-list flat class="how-can-list">
                        <v-list-item-group color="primary">
                          <router-link to="/beforeyoufly">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Arrival Guide</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                          <router-link to="/beforeyouflydeparture">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Departure Guide</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                          <router-link to="/beforeyouflytransitguide">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Transit Guide</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/travelchecklist">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Travel Checklist</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <v-list flat class="how-can-list">
                        <v-list-item-group color="primary">
                          <router-link to="/baggagerules">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Baggage Rules</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/beforeyouflywaitingtime">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Things to do</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/beforeyouflyall">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>View All</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="1" class="hidden-sm-and-down">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`${hover ? 'imgover' : 'imgleave'}`"
                  >
                    <v-img
                      :height="400"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'rounded-xl'
                          : 'rounded-l-xl'
                      "
                      src="../assets/images/atairportTab.jpg"
                    ></v-img>
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab href="#tab3"> At the Airport </v-tab>
          <v-tab-item value="tab3">
            <v-row>
              <v-col cols="12" md="4">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`${hover ? 'imgover' : 'imgleave'}`"
                  >
                    <v-img
                      :height="400"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'rounded-xl'
                          : 'rounded-r-xl'
                      "
                      src="../assets/images/atairportTab.jpg"
                    ></v-img>
                  </div>
                </v-hover>
              </v-col>
              <v-col cols="12" md="7">
                <v-card class="mx-auto" width="90%" :elevation="0">
                  <v-card-text>
                    <p class="text-h4 text--primary">At the Airport</p>
                    <p class="text--primary">
                      Never be bored at the airport again! Chennai Airport
                      offers an array of venues to keep travelers entertained,
                      from duty-free shopping to fine dining to cozy lounges.
                    </p>
                  </v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <v-list flat class="how-can-list">
                        <v-list-item-group color="primary">
                          <router-link to="/foodanddrink">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Food & Beverages</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/DutyFree">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>Duty Free</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/shopping">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>Shopping</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <v-list flat class="how-can-list">
                        <v-list-item-group color="primary">
                          <router-link to="/domestictodomestic">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Domestic/International Transfer
                                  Facilities</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                          <router-link to="/atairport">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>View All</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="1" class="hidden-sm-and-down">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`${hover ? 'imgover' : 'imgleave'}`"
                  >
                    <v-img
                      :height="400"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'rounded-xl'
                          : 'rounded-l-xl'
                      "
                      src="../assets/images/services.jpg"
                    ></v-img>
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab href="#tab1"> From & To the Airport </v-tab>
          <v-tab-item value="tab1">
            <v-row>
              <v-col cols="12" md="4">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`${hover ? 'imgover' : 'imgleave'}`"
                  >
                    <v-img
                      :height="400"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'rounded-xl'
                          : 'rounded-r-xl'
                      "
                      src="../assets/images/toandfrom-airport.jpg"
                    ></v-img>
                  </div>
                </v-hover>
              </v-col>
              <v-col cols="12" md="7">
                <v-card class="mx-auto" width="90%" :elevation="0">
                  <v-card-text>
                    <p class="text-h4 text--primary">From & To the Airport</p>
                    <p class="text--primary">
                      A comprehensive guide with everything you wanna know prior
                      to your flight from the Chennai International Airport!
                    </p>
                  </v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <v-list flat class="how-can-list">
                        <v-list-item-group color="primary">
                          <router-link to="/taxicabs">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Taxis / Cabs</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                          <router-link to="/carrentals">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Car Rentals</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                          <router-link to="/mlcp">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>Parking</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/tofromairport">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Metro Train</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <v-list flat class="how-can-list">
                        <v-list-item-group color="primary">
                          <router-link to="/publicbus">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Public Buses</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/pickupdropoff">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Pick up & Drop off</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <!-- <router-link to="/multimodalconnectivity">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Multimodal Connectivity</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link> -->
                          <router-link to="/suburbantrain">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Suburban Train</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/fromntotheairport">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>View All</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="1" class="hidden-sm-and-down">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`${hover ? 'imgover' : 'imgleave'}`"
                  >
                    <v-img
                      :height="400"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'rounded-xl'
                          : 'rounded-l-xl'
                      "
                      src="../assets/images/beforeflyTab.jpg"
                    ></v-img>
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab href="#tab4" class="border-radiusafter">
            Services & Facilities
          </v-tab>
          <v-tab-item value="tab4">
            <v-row>
              <v-col cols="12" md="4">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`${hover ? 'imgover' : 'imgleave'}`"
                  >
                    <v-img
                      :height="400"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'rounded-xl'
                          : 'rounded-r-xl'
                      "
                      src="../assets/images/services.jpg"
                    ></v-img>
                  </div>
                </v-hover>
              </v-col>
              <v-col cols="12" md="7">
                <v-card class="mx-auto" width="90%" :elevation="0">
                  <v-card-text>
                    <p class="text-h4 text--primary">Services & Facilities</p>
                    <!-- <p class="text--primary">
                                        Never be bored at the airport again! Chennai  Airport offers an array of venues to keep travelers entertained, from duty-free shopping to fine dining to cozy lounges.
                                    </p> -->
                  </v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <v-list flat class="how-can-list">
                        <v-list-item-group color="primary">
                          <router-link to="/mlcp">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>Parking</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/specialassistance">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Special Assistance</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                          <router-link to="/baggages">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Baggage Wrapping</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <!-- <router-link to="/freewifi">
                                                    <v-list-item>
                                                        <v-list-item-avatar>
                                                            <v-img  :src="require('../assets/images/rightArrow.svg')" />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Free Wi-Fi</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </router-link> -->
                          <!-- <router-link to="/indiapost">
                                                <v-list-item>
                                                    <v-list-item-avatar>
                                                            <v-img  :src="require('../assets/images/rightArrow.svg')" />
                                                        </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>India Post</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </router-link> -->

                          <!-- <router-link to="/lostfound">
                                                    <v-list-item>
                                                        <v-list-item-avatar>
                                                            <v-img  :src="require('../assets/images/rightArrow.svg')" />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Lost and Found</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </router-link> -->
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <v-list flat class="how-can-list">
                        <v-list-item-group color="primary">
                          <router-link to="/meetgreet">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Meet & Greet</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/selfcheckin">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Self Check-in</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/smokinglounge">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Smoking Lounge</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>

                          <router-link to="/services">
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-img
                                  :src="
                                    require('../assets/images/rightArrow.svg')
                                  "
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>View All</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </router-link>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="1" class="hidden-sm-and-down">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`${hover ? 'imgover' : 'imgleave'}`"
                  >
                    <v-img
                      :height="400"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'rounded-xl'
                          : 'rounded-l-xl'
                      "
                      src="../assets/images/toandfrom-airport.jpg"
                    ></v-img>
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <v-card
        v-scrollanimation
        class="mb-10"
        :class="{ ChennaiAirportSm: $vuetify.breakpoint.smAndDown }"
      >
        <v-img
          class="foodndrink"
          :class="{
            foodnddrinkImg: isfoodanddrink,
            shoppingImg: isshopping,
            dutyfreeImg: isdutyfree,
            relaxImg: isrelax,
          }"
        >
          <v-card
            flat
            width="80%"
            style="margin: 0 auto; background: none"
            class=""
          >
            <v-card-text class="mt-7 vertical-tab-title">
              <div class="mt-15 text-uppercase white--text font-weight-bold">
                experience
              </div>
              <p class="text-h3 white--text">Chennai Airport</p>
            </v-card-text>
            <v-tabs vertical class="vertical-tab mb-15">
              <v-tab class="" @click="shopping()">
                <v-icon left> mdi-arrow-right-drop-circle-outline </v-icon>
                Shopping
              </v-tab>

              <v-tab class="" @click="foodanddrink()">
                <v-icon left> mdi-arrow-right-drop-circle-outline </v-icon>
                Food & Beverages
              </v-tab>

              <v-tab class="" @click="relax()">
                <v-icon left> mdi-arrow-right-drop-circle-outline </v-icon>
                Relax & Unwind
              </v-tab>

              <v-tab class="" @click="dutyfree()">
                <v-icon left> mdi-arrow-right-drop-circle-outline </v-icon>
                Duty Free
              </v-tab>

              <v-tab-item>
                <v-card flat class="mx-auto" tile>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/unigadz.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Unigadz</v-list-item-title>
                          <v-list-item-subtitle
                            >Gadgets & Accessories</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="
                              require('../assets/images/houseofcashmere.png')
                            "
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            >House of Cashmere</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Clothing, Accessories</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/fairbrothers.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Fair Brothers</v-list-item-title>
                          <v-list-item-subtitle
                            >Clothing, Accessories</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-card-actions>
                    <v-btn
                      to="/shopping"
                      block
                      rounded
                      dark
                      class="gradient-button"
                      @click="gtrack()"
                    >
                      Explore All
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat class="mx-auto" tile>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/flyingbites.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Flying Bites</v-list-item-title>
                          <v-list-item-subtitle
                            >Cafe | Terminal 2 Departures</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/cafeccino.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Cafeccino</v-list-item-title>
                          <v-list-item-subtitle
                            >Cafe | Terminal 2 Departures</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/barista.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Barista</v-list-item-title>
                          <v-list-item-subtitle
                            >Cafe | Terminal 2 Departures</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-card-actions>
                    <v-btn
                      to="/foodanddrink"
                      block
                      rounded
                      large
                      dark
                      class="gradient-button"
                      @click="gtrack()"
                    >
                      Explore All
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat class="mx-auto" tile>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/lounge.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Lounge A</v-list-item-title>
                          <v-list-item-subtitle
                            >Lounge, Terminal (T-1)</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/lounge.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Lounge B</v-list-item-title>
                          <v-list-item-subtitle
                            >Lounge, Terminal (T-1)</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-card-actions>
                    <v-btn
                      to="/dutyfree"
                      block
                      rounded
                      dark
                      class="gradient-button"
                      @click="gtrack()"
                    >
                      Explore All
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat class="mx-auto" tile>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/JohnnieWalke.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Johnnie Walker</v-list-item-title>
                          <v-list-item-subtitle
                            >Liquor, Whiskey</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/burberry.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Burberry</v-list-item-title>
                          <v-list-item-subtitle
                            >Beauty, Perfume</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar rounded="0">
                          <v-img
                            :src="require('../assets/images/bounty.png')"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Bounty </v-list-item-title>
                          <v-list-item-subtitle
                            >Confectionery, chocolate</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-card-actions>
                    <v-btn
                      to="/dutyfree"
                      block
                      rounded
                      dark
                      class="gradient-button"
                      @click="gtrack()"
                    >
                      Explore All
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-img>
      </v-card>

      <v-row
        v-scrollanimation
        align="center"
        justify="center"
        :class="{ charmSmall: $vuetify.breakpoint.smAndDown }"
      >
        <v-col cols="12" md="6">
          <v-row align="center" justify="center">
            <v-col cols="6" sm="4">
              <v-hover>
                <v-card
                  flat
                  class="float-right mb-10 ml-2"
                  width="280"
                  slot-scope="{ hover }"
                  :class="`${hover ? 'imgover' : 'imgleave'}`"
                >
                  <v-img
                    class="rounded-xl"
                    :src="require('../assets/images/avsar1.jpg')"
                  ></v-img>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="6" sm="4">
              <v-hover>
                <v-card
                  flat
                  class="float-left mb-10 mr-2"
                  width="266"
                  slot-scope="{ hover }"
                  :class="`${hover ? 'imgover' : 'imgleave'}`"
                >
                  <v-img
                    class="rounded-xl"
                    :src="require('../assets/images/Avsar02.jpg')"
                  ></v-img>
                </v-card>
              </v-hover>
              <v-hover>
                <v-card
                  flat
                  class="float-left"
                  width="180"
                  slot-scope="{ hover }"
                  :class="`${hover ? 'imgover' : 'imgleave'}`"
                >
                  <v-img
                    class="rounded-xl"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-0' : 'mb-10'"
                    :src="require('../assets/images/Avsar03.jpg')"
                  ></v-img>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5">
          <v-card flat class="mx-auto float-left">
            <v-card-subtitle class="pl-5 text-uppercase page-title">
              Avsar
            </v-card-subtitle>
            <v-card-subtitle class="page-sub-title">
              <h1>Airport as Venue for Skilled Artisans Of The Region</h1>
            </v-card-subtitle>
            <v-card-text>
              <div class="text--primary pl-1 mb-5">
                Based on Govt. of India initiative for strengthening Self Help
                Groups (SHGs), it has been decided to allot space to SHGs at AAI
                Airports for setting up their self-produced products for sale.
                “AVSAR” (Airport as Venue for Skilled Artisans of the Region)
                Scheme is a unique initiative by the Airport Authority of India
                for helping and uplifting Self Help Groups (SHG) and local
                artisans.
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                to="/Avsar"
                rounded
                dark
                large
                class="gradient-button common-btn ml-4 pl-8 pr-8"
                :class="$vuetify.breakpoint.smAndDown ? 'mb-0' : 'mb-10'"
              >
                More Info
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="1"></v-col>
      </v-row>

      <v-row
        v-scrollanimation
        align="center"
        justify="center"
        :class="{ charmSmall: $vuetify.breakpoint.smAndDown }"
      >
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="5">
          <v-card flat class="mx-auto float-right">
            <v-card-subtitle class="pl-5 text-uppercase page-title">
              plan your trip
            </v-card-subtitle>
            <v-card-subtitle class="page-sub-title">
              <h1>Charming Chennai</h1>
            </v-card-subtitle>
            <v-card-text>
              <div class="text--primary pl-1">
                <p>
                  If you’re a first-time traveller, here are some experiences
                  that can help you plan your trip to Chennai.
                </p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                to="/charmingChennai"
                rounded
                dark
                large
                class="gradient-button common-btn ml-3 mb-5 pl-8 pr-8"
              >
                Let’s Go
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-hover>
            <v-card
              flat
              class="float-right mb-10"
              width="80%"
              slot-scope="{ hover }"
              :class="`${hover ? 'imgover' : 'imgleave'}`"
            >
              <v-img
                :class="
                  $vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-l-xl'
                "
                :src="require('../assets/images/frame.jpg')"
              ></v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
// @ is an alias to /src
import homeSlider from "../components/homeSlider";
import rightStickIcon from "../components/rightStickIcon";

export default {
  name: "HomeView",
  components: {
    homeSlider,
    rightStickIcon,
  },
  data() {
    return {
      isfoodanddrink: false,
      isshopping: false,
      isdutyfree: false,
      isrelax: false,
      searchFlight: "",
      selectFlightMode: "Arrivals",
    };
  },

  methods: {
    // METHODS TO TOGGLE THE DATA PROPERTIES TO TRUE OR FALSE
    foodanddrink() {
      this.isfoodanddrink = true;
      this.isshopping = false;
      this.isdutyfree = false;
      this.isrelax = false;
    },
    shopping() {
      this.isshopping = true;
      this.isfoodanddrink = false;
      this.isdutyfree = false;
      this.isrelax = false;
    },
    dutyfree() {
      this.isdutyfree = true;
      this.isfoodanddrink = false;
      this.isshopping = false;
      this.isrelax = false;
    },
    relax() {
      this.isrelax = true;
      this.isfoodanddrink = false;
      this.isshopping = false;
      this.isdutyfree = false;
    },
    findFlight() {
      this.$store.commit("setHomeFligthSearch", {
        searchFlight: this.searchFlight,
        selectFlightMode: this.selectFlightMode,
      });
      // flightinformation
      this.$router.push("/flightinformation");
    },

    gtrack() {
      this.$gtag.event("latest-release-click", {
        event_category: "documentation",
        event_label: "latest release button clicked",
        value: 1,
      });
    },
  },
};
</script>
<style type="text/css">
.rounded-r-xl .v-responsive__content,
.rounded-l-xl .v-responsive__content {
}
.searchBox {
  max-width: 1050px;
  margin: 0 auto;
}
.responsiveTab .v-image.rounded-xl {
  height: 200px !important;
  width: 80% !important;
}
.how-can-list .v-avatar.v-list-item__avatar {
  min-width: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

.how-can-list .v-list-item {
  border-bottom: 1px solid #e5e7eb;
  padding: 5px 0;
}
.v-slide-group__content.v-tabs-bar__content {
  margin-bottom: 12px;
}
.how-can-list .v-image__image.v-image__image--cover {
  background-size: inherit;
}
.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
/* .find-flight .text-h5{font-size: 20px; font-weight: 700 !important;}
.find-flight .text-md-h6{font-size: 20px; font-weight: 700}
.find-flight .text-xl-h4{font-size: 24px; font-weight: 700}
.find-flight .text-lg-h4{font-size: 24px; font-weight: 700} */
.find-flight .text-md-h5 {
  font-size: 24px !important;
  font-weight: 700;
}

.v-list-item__avatar {
  align-self: flex-start !important;
  margin-top: 12px !important;
}
.v-list-item__content {
  padding: 1px 0 1px 0 !important;
}

.buttonDesktop.col-sm-3 .v-btn {
  max-width: 75%;
  margin-left: 20px;
}

.foodndrink {
  background: url("../assets/images/experience-airport.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}
.foodndrinkImg {
  background: url("../assets/images/experience-airport.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}
.shoppingImg {
  background: url("../assets/images/shopping-img.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}
.dutyfreeImg {
  background: url("../assets/images/dutyfree.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}

.relaxImg {
  background: url("../assets/images/relax.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}

.searchTag {
  margin: 20px 0 0 0;
}
.searchTag p {
  font-size: 18px;
  color: #374151;
  line-height: 28px;
  display: inline;
  margin-right: 20px;
  display: block;
}
.searchTag span {
  font-size: 14px;
  color: #1b63f8;
  line-height: 24px;
  margin-right: 20px;
  background: #f3f4f6;
  padding: 3px 25px 3px 14px;
  display: inline-block;
  border-radius: 15px;
  background-image: url("../assets/images/arrow.svg");
  background-position: 92% 11px;
  background-repeat: no-repeat;
}
.searchTagSm p {
  font-size: 16px !important;
}
.searchTagSm span {
  font-size: 12px !important;
}
</style>

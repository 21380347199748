<template>

 <div class="rightIcons">
      <ul>
        <li>
    <button class="learn-more">
    <span class="circle" aria-hidden="true">
      <span class="icon arrow">
          <router-link  to="/feedback"> <v-img :src="require('../assets/images/bx_chat.svg')" /></router-link>
      </span>
        
    </span>
  
    <router-link  to="/feedback" class="button-text">Feedback</router-link>
  </button>
  </li>

   <!-- <li>
    <button class="learn-more">
    <span class="circle" aria-hidden="true">
      <span class="icon arrow">
        <v-img :src="require('../assets/images/mlcp-icon.svg')" />
      </span>
        
    </span>
    <span class="button-text">Airport Maps</span>
  </button>
  </li> -->


   <li>
    <button class="learn-more">
    <span class="circle" aria-hidden="true">
      <span class="icon arrow">
         <router-link  to="/mlcp"> <v-img :src="require('../assets/images/tabler_stack.svg')" /></router-link>
      </span>
        
    </span>
   
    <router-link  to="/mlcp" class="button-text">MLCP</router-link>
  </button>
  </li>
      
      </ul>

   

   


</div>
  
</template>

<script>
export default {
    name: 'rightStickIcon',
   
}
</script>

<style scoped>




.rightIcons { position: fixed; right: 0; top: 45%; z-index: 1;}
.rightIcons ul li { list-style: none; margin-bottom: 10px;}

button.learn-more {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  width: 12rem;
  height: auto;
}
button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.8rem;
  height: 3rem;
  background: #00308F;
  border-radius: 1.625rem  0 0 1.625rem;
  float: right;
}
button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 10px;
  bottom: 0;
  margin: auto;
  right:0.2rem;
  /* background: #fff; */
  
}
/* button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  right: 1.2rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
} */
/* button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
} */
button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 2.5rem;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: capitalize;
  display: none;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  /* background: #fff; */
  transform: translate(-0.5rem,0rem);
}
button:hover .button-text {
  color: #fff;
  display: block;

  transition: transform .5s ease-in-out;}
 
</style>
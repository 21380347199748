<template>
    <v-carousel cycle  :show-arrows="false" hide-delimiter-background class="myslider zoom-in-out-box">
        <v-carousel-item v-for="(item,i) in items" :key="i" :src="item.src" transition="fade-transition" >
            <div dark class="overlay">
                <v-row class="fill-height " align="center" justify="center" style="height: 500px">
                    <v-col>
                        <v-container class="homeslider" :class="{'responisiveSliderHome': $vuetify.breakpoint.smAndDown}">
                        
                            <div class="slider-title"  :class="$vuetify.breakpoint.smAndDown?'mt-15':'mt-0'">
                                {{ item.title }}
                            </div>
                            <div class="slider-subtitle"  >
                                {{ item.subtitle }}
                            </div>
                             <!-- <v-btn rounded dark large class="gradient-button pl-4 pr-4 mt-10">
                                More Info
                            </v-btn> -->
                        
                    </v-container>
                    </v-col>
                </v-row>
            </div>
        </v-carousel-item>
    </v-carousel>
</template>
<script>
export default {
    name: 'homeSlider',
    data() {
        return {
            items: [{
                    src: require('../assets/slider/Homepage1.jpg'),
                    title: "எளிதுவாக பின்தொடருங்கள் மற்றும் தென் இந்தியாவின் வாயிலை அரசுக்கு அறிந்துகொள்ளுங்கள், சென்னை சர்வதேச விமான நிலையத்தின் மூலம்",
                    // subtitle: "Welcome to Chennai, where culture and commerce take flight."
                },
                {
                    src: require('../assets/slider/Homepage2.jpg'),
                    title: "கட்டடக்கலைச் சிறப்பின் சின்னம்",
                  

                },
                {
                    src: require('../assets/slider/Homepage3.jpg'),
                    title: "நாடுகளை இணைத்தல், உலகமயமாதலை நிறைவு செய்தல்",
                    

                },
                {
                    src: require('../assets/slider/Homepage4.jpg'),
                    title: "உங்கள் கனவுகள் உங்கள் இலக்குகளுக்கு கரிகாலம் பெறுகின்றன!",
                   

                },

                 {
                    src: require('../assets/slider/Homepage5.jpg'),
                    title: "கலைத்திறனும் கட்டிடக்கலையும் சங்கமிக்கும் இடம்",
                    

                },

                 {
                    src: require('../assets/slider/Homepage6.jpg'),
                    title: "மறக்க முடியாத பயண அனுபவங்களுக்கு சிறகுகள் முளைக்கின்றன.",
                  

                },
               

            ],
        }
    },
}
</script>
<style scoped>
.v-carousel .v-window-item {
  position: absolute;
  top: 0;
  width: 100%;
}
.fade-transition-enter-fron{opacity: 0 !important;}
.fade-transition-enter-to{opacity: 1 !important}
.fade-transition-enter-active{transition: all 5s ease !important; }
.fade-transition-leave-fron{opacity: 1 !important;}
.fade-transition-leave-to{opacity: 0 !important}
.fade-transition-leave-active{
    transition: all 5s ease !important;
}


.overlay {
    width: 100%;
    height: 100%;
    /*background: linear-gradient(180deg, rgba(17, 24, 39, 0.2) 0.08%, rgba(17, 24, 39, 0.4) 41.89%, rgba(17, 24, 39, 0.8) 100%);*/
    background:rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
}



.fill-height {
    color: #fff;
}
.slider-title{
    font-size: 30px; font-weight: 700; width: 50%; line-height: 60px;
}
.slider-subtitle{
    font-size: 18px; font-weight: 500; 
}

 
</style>
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    counter: 0,
    error_msg: "",
    success_msg: "",
    users_data: [],
    flight: {},
    homeSearch: {},
    airline: {},
    productId: "",
    selectedLang: "EN",
    forContent: "",
  },
  mutations: {
    usersData(state, payload) {
      return (state.users_data = payload);
    },
    errorMessage(state, payload) {
      return (state.error_msg = payload);
    },
    setFlightData(state, data) {
      state.flight = { ...data };
    },
    setAirlineData(state, data) {
      state.airline = { ...data };
    },
    setHomeFligthSearch(state, data) {
      state.homeSearch = { ...data };
    },
    setproductId(state, data) {
      state.productId = data;
    },
    setLanguage(state, data) {
      state.selectedLang = data;
    },
    setContentID(state, data) {
      state.forContent = data;
      localStorage.setItem("content_id", data);
    },
  },
  actions: {},
  getters: {
    tUsersData(state) {
      return state.users_data;
    },
    getErrorMessage(state) {
      return state.error_msg;
    },
    getSuccessMsg(state) {
      return state.success_msg;
    },
    getFlightData(state) {
      return state.flight;
    },
    getAirlineData(state) {
      return state.airline;
    },
    getHomeFligthSearch(state) {
      return state.homeSearch;
    },
    getProductId(state) {
      return state.productId;
    },
    getLanguage(state) {
      return state.selectedLang;
    },
    getContentID(state) {
      return state.forContent;
    },
  },
  modules: {},
});

<template>
  <div class="">
    <HomeView v-if="language == 'EN' || language == 'en'" />
    <HomeViewHindi v-if="language == 'HI' || language == 'hi'"/>
    <HomeViewTamil v-if="language == 'TA' || language == 'ta'"/>

  </div>
</template>
  <script>
import EventBus from "../../event-bus";
import HomeView from "../HomeView.vue";
import HomeViewHindi from "../HomeViewHindi.vue";
import HomeViewTamil from "../HomeViewTamil.vue";


export default {
  name: "HomePageTemplate",
    // props: ["content"],
  components: {
    HomeView,
    HomeViewHindi,
    HomeViewTamil
  },
  created() {
    // Sets up the Event Bus listener using
    // the custom event name and assosciates
    // it with a component method.
    EventBus.$on("contentChange", (data) => {});
  },

  data() {
    return {};
  },
  watch: {},
  computed: {
    language: {
      get() {
        return this.$store.getters.getLanguage;
      },
      set(lang) {
        this.$store.commit("setLanguage", lang);
      },
    },
  },
  mounted() {},

  methods: {},
};
</script>
  